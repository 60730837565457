header{
    height: 100vh;
    padding: 7rem;
    overflow: hidden;
}

.header_container{
    text-align: center;
    height: 100%;
    position: relative;
}

/*---------CTA-----------*/

.cta{
    margin-top: 2.5rem;
    display: flex;
    gap:1.2rem;
    justify-content: center;
}

/*---------socials-----------*/

.header_socials{
    font-size: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    left: 0;
    bottom: 3rem ;
}

/*---------image-----------*/

.me{
   
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 11rem);
    /* padding:5rem 1.5rem 1.5rem 1.5rem; */
    padding:3rem;
    overflow:hidden;
}

.me img{
    border-radius: 50%;
}

/*-------------------- MEDIA QUERIES (MEDIUM DEVICES)--------*/

@media screen and (max-width:1024px){
    header{
        height: 100vh;
    }
    
.me{
   
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 11rem);
    /* padding:5rem 1.5rem 1.5rem 1.5rem; */
    padding:3rem;
    overflow:hidden;
}


}


/*-------------------- MEDIA QUERIES (SMALL DEVICES)--------*/

@media screen and (max-width:600px){
   
    header{
        height: 130vh;
    }

    .header_socials{
        display: none;
    }

   
}
