.exp_container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap:2rem;
}

.exp_container > div{
    background: var(--color-bg-variant);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border:1px solid transparent;
    transition: var(--transition);
}


.exp_container > div:hover{
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.exp_container > div h3{
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-white);
}

.exp_content{
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap:2rem;
}

.exp_details{
    display: flex;
    gap:1rem;
}

.exp_details-icon{
    margin-top: 6px;
    color: var(--color-white);    
}


/*-------------------- MEDIA QUERIES (MEDIUM DEVICES)--------*/

@media screen and (max-width:1024px){
    .exp_container{
        grid-template-columns: 1fr;
    }

    .exp_container > div{
        width: 80%;
        padding:2rem;
        margin: 0 auto;
    }

    .exp_content{
        padding: 1rem;
    }
}


/*-------------------- MEDIA QUERIES (SMALL DEVICES)--------*/

@media screen and (max-width:600px){
   .exp_container{
    gap:1rem;
   }

   .exp_container > div{
    width: 100%;
    padding: 2rem 1rem;
   }
   
}



