
.service_container{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap:3rem;
}

.service{
    background-color: var(--color-bg-variant);
    border-radius: 2rem;
    border:1px solid var(--color-primary);
    height: fit-content;
    transition: var(--transition);
}

.service:hover{
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.service_head{
    padding:2rem;
}

.service_head{
    color: var(--color-white);
    font-size: 1rem;
    text-align: center;
}

.service_list{
    padding:2rem;
}

.service_list li{
    display: flex;
    gap:1rem;
    margin-bottom: 0.8rem;
}


.service_list-icon{
    margin-top: 2px;
}

.service_list p{
    font-size: 0.9rem;
}



@media screen and (max-width:1024px){
    .service_container{
        grid-template-columns: 1fr 1fr;
        gap:2rem;
    }
    
    .service{
        height: auto;
    }
}


/*-------------------- MEDIA QUERIES (SMALL DEVICES)--------*/

@media screen and (max-width:600px){
   .service_container{
    grid-template-columns: 1fr;
    gap:1.5rem;
    
    
   }
   
   
}
