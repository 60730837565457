nav{
    background:rgba(0,0,0,0.3);
    width: max-content;
    display: block;
    padding: 0.7rem 1.7rem;
    z-index:2;
    position: fixed;
    left: 92%;
    flex-direction: column;
    transform:translateX(-50%);
    bottom: 9rem;
    display: flex;
    gap: 0.8rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px);
}

nav a{
    background: transparent;
    padding:0.9rem;
    border-radius: 50%;
    display: flex;
    color: var(--color-light);
    font-size: 1.3rem;
}

nav a:hover{
    background: rgba(0,0,0,0.2);
}

nav a.active{
    background: var(--color-primary);
    color:var(--color-bg)
}

/*-------------------- MEDIA QUERIES (MEDIUM DEVICES)--------*/

@media screen and (max-width:1024px){
   nav{
        padding: 0.7rem 1rem;
   }
}


/*-------------------- MEDIA QUERIES (SMALL DEVICES)--------*/

@media screen and (max-width:600px){
   
  
    nav{
        padding:0.7rem 0.2rem;
    }
   
}